body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: black;
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  font-style: normal;
  src: local('Inter-Thin'), url('../fonts/Inter-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 200;
  font-style: normal;
  src: local('Inter-ExtraLight'), url('../fonts/Inter-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 300;
  font-style: normal;
  src: local('Inter-Light'), url('../fonts/Inter-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: local('Inter-Regular'), url('../fonts/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: local('Inter-Medium'), url('../fonts/Inter-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: local('Inter-SemiBold'), url('../fonts/Inter-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: local('Inter-Bold'), url('../fonts/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: normal;
  src: local('Inter-ExtraBold'), url('../fonts/Inter-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-style: normal;
  src: local('Inter-Black'), url('../fonts/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: normal;
  src: local('Poppins-Thin'), url('../fonts/Poppins-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: italic;
  src: local('Poppins-ThinItalic'), url('../fonts/Poppins-ThinItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: normal;
  src: local('Poppins-ExtraLight'), url('../fonts/Poppins-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  font-style: italic;
  src: local('Poppins-ExtraLightItalic'), url('../fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: local('Poppins-Light'), url('../fonts/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: italic;
  src: local('Poppins-LightItalic'), url('../fonts/Poppins-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: local('Poppins-Regular'), url('../fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: local('Poppins-Medium'), url('../fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: italic;
  src: local('Poppins-MediumItalic'), url('../fonts/Poppins-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: local('Poppins-SemiBold'), url('../fonts/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: italic;
  src: local('Poppins-SemiBoldItalic'), url('../fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: local('Poppins-Bold'), url('../fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: italic;
  src: local('Poppins-BoldItalic'), url('../fonts/Poppins-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  src: local('Poppins-ExtraBold'), url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: italic;
  src: local('Poppins-ExtraBoldItalic'), url('../fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: normal;
  src: local('Poppins-Black'), url('../fonts/Poppins-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  font-style: italic;
  src: local('Poppins-BlackItalic'), url('../fonts/Poppins-BlackItalic.ttf') format('truetype');
}

:root {
  --scroll-bar-color: #8696bb;
  --scroll-bar-bg-color: #fff;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
